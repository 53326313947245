import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { collection, getDoc, getDocs, addDoc, doc, updateDoc, serverTimestamp } from 'firebase/firestore';

const StartRoomComponent = () => {
    const [pieces, setPieces] = useState([]);
    const [selectedPiece, setSelectedPiece] = useState("");
    const [roomLink, setRoomLink] = useState("");
    const [roomId, setRoomId] = useState(null);
    const [counter, setCounter] = useState(null); // Counter state
    const [isPlaying, setIsPlaying] = useState(false); // Playback state
    const [bpm, setBpm] = useState(null); // Store BPM
    const [beatPerMeasure, setBeatPerMeasure] = useState(null); // Store Beat Per Measure


    useEffect(() => {
        let interval = null;

        if (isPlaying && counter !== null) {
            interval = setInterval(() => {
                setCounter((prev) => {
                    if (prev < beatPerMeasure) {
                        return prev + 1;
                    } else {
                        clearInterval(interval);
                        setIsPlaying(false); // Stop playback when counter reaches beatPerMeasure
                        return null; // Reset counter
                    }
                });
            }, (60 / bpm) * 1000); // Interval based on BPM
        }

        return () => clearInterval(interval); // Cleanup interval on unmount or state change
    }, [isPlaying, counter, bpm, beatPerMeasure]);

    useEffect(() => {
        const fetchPieces = async () => {
            try {
                const snapshot = await getDocs(collection(db, 'pieces'));
                const pieceList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setPieces(pieceList);
            } catch (error) {
                console.error("Error fetching pieces:", error);
            }
        };

        fetchPieces();
    }, []);

    const handlePieceSelection = async (pieceId) => {
        setSelectedPiece(pieceId);
    
        if (pieceId) {
            try {
                const pieceDocRef = doc(db, 'pieces', pieceId);
                const pieceDoc = await getDoc(pieceDocRef);
    
                if (pieceDoc.exists()) {
                    let { bpm, beatPerMeasure } = pieceDoc.data();
    
                    // Prompt for missing BPM
                    if (!bpm) {
                        bpm = parseFloat(prompt("BPM is missing. Please enter the BPM for this piece:"));
                        if (!bpm || isNaN(bpm)) {
                            alert("Invalid BPM value. Please select a valid piece.");
                            setSelectedPiece(""); // Reset selection
                            return;
                        }
                    }
    
                    // Prompt for missing Beat Per Measure
                    if (!beatPerMeasure) {
                        beatPerMeasure = parseFloat(prompt("Beat Per Measure is missing. Please enter the Beat Per Measure for this piece:"));
                        if (!beatPerMeasure || isNaN(beatPerMeasure)) {
                            alert("Invalid Beat Per Measure value. Please select a valid piece.");
                            setSelectedPiece(""); // Reset selection
                            return;
                        }
                    }
    
                    // Save the updated values back to Firestore
                    await updateDoc(pieceDocRef, { bpm, beatPerMeasure });
    
                    // Update state with the fetched or prompted values
                    setBpm(bpm);
                    setBeatPerMeasure(beatPerMeasure);
                } else {
                    alert("Error: Selected piece metadata not found.");
                    setSelectedPiece(""); // Reset selection
                }
            } catch (error) {
                console.error("Error fetching piece metadata:", error);
            }
        } else {
            setBpm(null);
            setBeatPerMeasure(null);
        }
    };

    const handleStartRoom = async () => {
        if (!selectedPiece) {
            alert("Please select a piece.");
            return;
        }

        try {
            const roomData = {
                pieceId: selectedPiece,
                createdAt: new Date(), // Use the current timestamp
                playbackState: {
                    command: "idle", // Initial playback state
                    timestamp: null // Timestamp for synchronization
                }
            };

            // Save the room in Firestore
            const roomDocRef = await addDoc(collection(db, 'rooms'), roomData);
            const newRoomId = roomDocRef.id;

            // Generate the room link
            const link = `${window.location.origin}/room`;
            setRoomLink(link);
            setRoomId(newRoomId);
        } catch (error) {
            console.error("Error starting room:", error);
        }
    };

    const sendPlaybackCommand = async (command) => {
        if (!roomId) {
            alert("Please start a room first.");
            return;
        }
    
        try {
            // Fetch the selected piece's metadata to get BPM and beatPerMeasure
            const pieceDocRef = doc(db, 'pieces', selectedPiece);
            const pieceDoc = await getDoc(pieceDocRef);
    
            if (!pieceDoc.exists()) {
                alert("Error: Selected piece metadata not found.");
                return;
            }
    
            let { bpm, beatPerMeasure } = pieceDoc.data();
    
            // Save the updated values back to Firestore
            await updateDoc(pieceDocRef, { bpm, beatPerMeasure });

            if (command === "play") {
                setCounter(1); // Start counter at 1
                setIsPlaying(true); // Start playback
            } else {
                setIsPlaying(false); // Stop playback
                setCounter(null); // Reset counter
            }
            
            const delay = command === "play" ? (beatPerMeasure / bpm) * 60000 : 0;
            const roomDocRef = doc(db, 'rooms', roomId);
    
            // Update the playbackState field in the room document
            setTimeout(async () => {
                await updateDoc(roomDocRef, {
                    playbackState: {
                        command,
                        timestamp: serverTimestamp() // Use Firestore server timestamp
                    }
                });
            }, delay);
    

        } catch (error) {
            console.error("Error sending playback command:", error);
        }
    };
    return (
        <div className="container my-5 p-4 bg-light rounded shadow">
            <h3>Start a Room</h3>
            <div className="mb-3">
                <label htmlFor="pieceSelect" className="form-label">Select a Piece</label>
                <select
                    id="pieceSelect"
                    value={selectedPiece}
                    onChange={(e) => handlePieceSelection(e.target.value)}
                    className="form-control"
                >
                    <option value="">Select a piece</option>
                    {pieces.map(piece => (
                        <option key={piece.id} value={piece.id}>
                            {piece.pieceName}
                        </option>
                    ))}
                </select>
            </div>
            <button onClick={handleStartRoom} className="btn btn-primary">
                Start a Room
            </button>
            {roomLink && (
                <div className="mt-3">
                    <p>Room link:</p>
                    <a href={roomLink} target="_blank" rel="noopener noreferrer">
                        {roomLink}
                    </a>
                </div>
            )}
            {roomId && (
                <div className="mt-4">
                    <h3>Playback Controls</h3>
                    <div className="d-flex gap-2">
                        <button onClick={() => sendPlaybackCommand("play")} className="btn btn-success">
                            Play
                        </button>
                        <button onClick={() => sendPlaybackCommand("pause")} className="btn btn-warning">
                            Pause
                        </button>
                        <button onClick={() => sendPlaybackCommand("restart")} className="btn btn-danger">
                            Go Back to Beginning
                        </button>
                    </div>
                </div>
            )}
            {isPlaying && counter !== null && (
                <div className="mt-3">
                    <h4>Counter: {counter}</h4>
                </div>
            )}
        </div>
    );
};

export default StartRoomComponent;