import React, { useState, useEffect } from 'react';
import { auth, db } from './firebase'; // Firebase configuration
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import UploadComponent from './UploadComponent';
import DeleteFileComponent from './DeleteFileComponent';
import ToggleVisibilityComponent from './ToggleVisibilityComponent';
import StartRoomComponent from './StartRoomComponent';

const UploadPage = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [showLogin, setShowLogin] = useState(false); // Controls login form visibility
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        const checkAdmin = async (user) => {
            if (!user) {
                setShowLogin(true);
                setLoading(false);
                return;
            }

            try {
                const rolesDocRef = doc(db, 'users', user.uid);
                const rolesDoc = await getDoc(rolesDocRef);

                if (rolesDoc.exists() && rolesDoc.data().role === "admin") {
                    setIsAdmin(true);
                } else {
                    setError("Unauthorized access. Admins only.");
                }
            } catch (err) {
                console.error("Error checking admin role:", err);
                setError("Error checking access permissions.");
            } finally {
                setLoading(false);
            }
        };

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            checkAdmin(user);
        });

        return () => unsubscribe(); // Cleanup on component unmount
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true);

        try {
            await signInWithEmailAndPassword(auth, email, password);
            setShowLogin(false); // Close the login form after successful login
        } catch (err) {
            console.error("Error logging in:", err);
            setError("Invalid email or password. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (showLogin) {
        return (
            <div className="container my-5">
                <h1 className="text-center">Admin Login</h1>
                <form onSubmit={handleLogin} className="mt-4">
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            id="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input
                            type="password"
                            id="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">
                        Login
                    </button>
                </form>
                {error && <p className="text-danger text-center mt-3">{error}</p>}
            </div>
        );
    }

    if (error) {
        return <div className="text-danger text-center mt-5">{error}</div>;
    }

    return (
        <div className="container my-5">
            <h1 className="text-center mb-5">Upload Page</h1>
            {isAdmin && <UploadComponent />}
            {isAdmin && <DeleteFileComponent />}
            {isAdmin && <ToggleVisibilityComponent />}
            {isAdmin && <StartRoomComponent />}
        </div>
    );
};

export default UploadPage;