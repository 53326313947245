import React, { useEffect, useState, useRef } from 'react';
import { db } from './firebase';
import { collection, query, orderBy, limit, getDocs, doc, getDoc, onSnapshot } from 'firebase/firestore';

const RoomPage = () => {
    const [roomData, setRoomData] = useState(null);
    const [roomId, setRoomId] = useState(null); // Store the Firestore-generated room ID
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState("");
    const [audioUrl, setAudioUrl] = useState(""); // Store the audio URL for the selected voice
    const audioRef = useRef(null);

    useEffect(() => {
        const fetchLatestRoom = async () => {
            try {
                // Query the latest room based on the createdAt field
                const roomsQuery = query(collection(db, 'rooms'), orderBy('createdAt', 'desc'), limit(1));
                const roomsSnapshot = await getDocs(roomsQuery);

                if (!roomsSnapshot.empty) {
                    const latestRoomDoc = roomsSnapshot.docs[0];
                    const roomInfo = latestRoomDoc.data();
                    setRoomData(roomInfo);
                    setRoomId(latestRoomDoc.id); // Use the Firestore-generated document ID

                    // Fetch voices for the selected piece
                    const voicesSnapshot = await getDocs(collection(db, `pieces/${roomInfo.pieceId}/voices`));
                    const voiceList = voicesSnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setVoices(voiceList);
                } else {
                    console.error("No rooms found.");
                }
            } catch (error) {
                console.error("Error fetching the latest room:", error);
            }
        };

        fetchLatestRoom();
    }, []);

    useEffect(() => {
        if (!roomId) return;

        const roomDocRef = doc(db, 'rooms', roomId);

        // Listen for playback state changes
        const unsubscribe = onSnapshot(roomDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                const { command, timestamp } = data.playbackState;

                if (command === "play" && timestamp) {
                        audioRef.current.play();
                } else if (command === "pause") {
                    audioRef.current.pause();
                } else if (command === "restart") {
                    audioRef.current.currentTime = 0;
                    audioRef.current.pause();
                }
            }
        });

        return () => unsubscribe();
    }, [roomId]);

    const handleVoiceSelection = async (e) => {
        const selectedVoiceId = e.target.value;
        setSelectedVoice(selectedVoiceId);

        if (selectedVoiceId) {
            try {
                // Fetch the audio URL for the selected voice
                const voiceDocRef = doc(db, `pieces/${roomData.pieceId}/voices`, selectedVoiceId);
                const voiceDoc = await getDoc(voiceDocRef);

                if (voiceDoc.exists()) {
                    setAudioUrl(voiceDoc.data().url); // Use the correct field name for the audio URL
                } else {
                    console.error("Voice not found.");
                }
            } catch (error) {
                console.error("Error fetching voice data:", error);
            }
        } else {
            setAudioUrl(""); // Clear the audio URL if no voice is selected
        }
    };

    if (!roomData) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container my-5">
            <h1 className="text-center">Room for {roomData.pieceId}</h1>
            <p>Share this link with others to join the room!</p>

            <div className="mt-4">
                <h3>Select a Voice</h3>
                <select
                    className="form-control mb-3"
                    value={selectedVoice}
                    onChange={handleVoiceSelection}
                >
                    <option value="">Select a voice</option>
                    {voices.map(voice => (
                        <option key={voice.id} value={voice.id}>
                            {voice.voice} {/* Use the correct field name for the voice name */}
                        </option>
                    ))}
                </select>
            </div>

            {audioUrl && (
                <div className="mt-4">
                    <h3>Playback</h3>
                    <audio
                        ref={audioRef}
                        src={audioUrl} // Use the fetched audio URL
                        preload="auto"
                        controls
                    ></audio>
                </div>
            )}
        </div>
    );
};

export default RoomPage;